import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Modal } from 'react-bootstrap'
import $ from "jquery";
import ScrollAnimation from 'react-animate-on-scroll';
import './assets/styles/_index.scss'

const StickyBar = (props) => {
    const [activeTab, setActiveTab] = useState('#key_features')
    const [isOpen, setIsOpen] = useState(false)
    let types = []
    props?.Add_Campaign_Module?.map((i) => {
        if (i.Show_In_Menu && i.type) {
            types.push(i.type.replace(/STRAPI__COMPONENT_CAMPAIGN_MODULES_/g, ""));
        }
    });
    types = types.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);
    let navList = [
        { title: 'Key features', id: '#key_features', type: "key_features" },
        { title: 'Connectivity', id: '#connectivity', type: "connectivity" },
        { title: 'Facilities', id: '#facilities', type: "facilities" },
        { title: 'Payment Plan', id: '#payment_plan', type: "payment_plan" },
        { title: 'Floorplans', id: '#floorplans', type: "floorplans" },
        { title: 'Area guide', id: '#area_guide', type: "area_guide" },     
        { title: 'Download Your Brochure', id: '#download_your_brochure', type: "download_your_brochure" },
       
    ]   
    const handleClick = (val) => {
        setActiveTab(val)
        const element = document.querySelector(val);
      // console.log("element.getBoundingClientRect().top", element, val)
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top: element.getBoundingClientRect().top+150
            })
        }
    }
    useEffect(() => {
        if (window) {
            $(window).scroll(function () {
                var windscroll = $(window).scrollTop();
                if (windscroll >= 100) {
                    $('.section-menu-block').each(function (i) {
                        if ($(this).position().top <= windscroll + 150) {
                            $('.sticky-menu-list a.active').removeClass('active');
                            $('.sticky-menu-list a').eq(i).addClass('active');
                        }
                    });
                } else {
                    $('a.active').removeClass('active');
                    $('a:first').addClass('active');
                }
            }).scroll();

            // if(window.location.hash) {
            //     const element = document.querySelector(window.location.hash);
            //     if (element) {
            //         window.scrollTo({
            //             behavior: 'smooth',
            //             top: element.getBoundingClientRect().top+150
            //         })
            //     }
            // }
        }
    })

   return (
        <section className={`nav-sticky-wrapper d-flex align-items-center sticky-top`}>
            <Container>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                            <ul className="list-inline nav-sticky-list d-flex align-items-center">
                                {navList.map((list, i) => {
                                   // if (types.includes(list.type)) {
                                        return (
                                            <li className="list-inline-item sticky-menu-list" key={i}>
                                                <a href={`${list.id}`} className={activeTab === list.id ? 'active' : ''} onClick={(e) => handleClick(list.id)}>{list.title}</a>
                                            </li>
                                        )                                   
                                    //}
                                })}
                            </ul>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default StickyBar